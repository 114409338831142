// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
    clear: both;
    content: '';
    display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
    overflow: hidden;
    padding: 0; /* 1 */
    text-indent: 101%;
    white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    height: 1px;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mobileOnly {
    @include responsive(">medium") {
        display: none;
    }
}

.mobileHidden {
    @include responsive("<medium") {
        display: none;
    }
}

%absoluteFull {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

.maxWidthContent {
    max-width: 1054px;
    margin-left: auto;
    margin-right: auto;
}

.noPadding {
    padding: 0 !important;
}

%cover {
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

%linkabs {
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}