

@mixin scrollbar($behavior: smooth, $background: #fff, $foreground: #061F33) {
    scroll-behavior: $behavior;
    overflow: auto;
    scrollbar-color: $foreground $background;
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground;
        border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $foreground;
    }

    &::-webkit-scrollbar-thumb:active {
        background: $foreground;
    }

    &::-webkit-scrollbar-track {
        background: $background;
    }
}
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}

//create media queries
@mixin responsive($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    }

    @if str-slice($breakpoint, 0, 1) == ">" and map-has-key($breakpoints, str-slice($breakpoint, 2, -1)) {
        @media (min-width: #{map-get($breakpoints, str-slice($breakpoint, 2, -1))}) {
            @content;
        }
    }

    @if str-slice($breakpoint, 0, 1) == "<" and map-has-key($breakpoints, str-slice($breakpoint, 2, -1)) {
        @media (max-width: #{map-get($breakpoints, str-slice($breakpoint, 2, -1)) - 1px} ) {
            @content;
        }
    }
}

@mixin on-event($self: false) {
    @if $self {
        &,
        &:hover,
        &:active {
            @content;
        }
    }
    @else {
        &:hover,
        &:active {
            @content;
        }
    }
}

@mixin on-hover {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }
}

//fonts
// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff) {
    $src: null;

    $extmods: ( eot: "?", svg: "#" + str-replace($name, " ", "_") );

    $formats: ( otf: "opentype", ttf: "truetype" );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
        font-display: swap;
    }
}

