// -----------------------------------------------------------------------------
// This file contains all styles related to content.
// -----------------------------------------------------------------------------

.content {
    --pspace: 16px;
 
    @include responsive('>small') {
        display: flex;
        align-items:center;
        gap:var(--cspace);
    }

    &__desc {
        max-width:800px;
    }
    &__img {
       
        // clip-path: polygon(0% 20%, 0% 100%, 100% 80%, 100% 0%);
        line-height:1;
        margin:0;
        
        @include responsive('>small') {
            width: 20vw;
        }
        @include responsive('<small') {
            width: 100%;
            max-width:180px;
            margin:0 auto 16px;
        }
        picture {
            aspect-ratio: 1;
        }
        img {
            height:100%;
            width:100%;
            object-fit: cover;
            border-radius:50%;
        }

    }
    &__list {
        padding:0;  
        display: flex;
        flex-wrap:wrap;
        align-items:center;
        position:relative;
        gap: 8px;

        li {
            display: inline-flex;
            align-items:center;
            position:relative;
            gap: 8px;


            &:before {
                content:"";
                background:var(--green);
                width:8px;
                height:8px;
                border-radius:50%;
            }
        }
    }

}