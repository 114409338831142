.brands {
    display: grid;  
   
    
    @include responsive('>xlarge') {
        gap:24px;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
    @include responsive('<xlarge') {
        @include responsive('>small') {
            gap:16px;
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        }
    }
    @include responsive('<small') {
        gap:8px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
    &__item {
        border:solid 1px var(--brown);
        padding:8px;
        text-align:center;
        place-content:center;
        aspect-ratio: 1.9;
        background:var(--white);

        &--ikea {
            padding:0;
            background:#0058a3;
            border-color:#0058a3;
        }
        &--hollandandbarrett {
            background:#00594c;
            border-color:#00594c;
        }
        &--Aegean {
            background:   #002677;
            border-color:#002677;
        }
        &--SustainableGreece{
            background:#DDE6EB;
            border-color:#DDE6EB;
        }
        &--vianex{
            background:#000938;
            border-color:#000938;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}