$transition: .25s;
.header {
    --radius:36px;
    --wrapper:100;
    min-height: var(--header);
    width: 100%;    
    top: 0;
    left: 0;
    z-index: 10;   
    display: flex;
    align-items:center;
    position: fixed;
    @include responsive(">large") {
        padding-bottom:24px;
    }
    @include responsive("<large") {
        padding-bottom:8px;
       
    }
    &:after {
        content:"";
        position:absolute;
        inset:0;
        background:var(--whitevsblack);
        clip-path: var(--polygon); 
        z-index: -1;
    }

    &__wrapper {      
     
        display: flex;
        align-items: center;
        justify-content: space-between;          
       
    }

   
    &__nav {
        @include responsive('<xlarge') {
            background-color: var(--beige);
            left: 0;
            margin: 0;
            @include scrollbar();
            position: fixed;
            top: 0;
            width: 100%;
            height: 100%;
            transition: transform 450ms ease-in-out;
            padding: calc(var(--header) + 32px)  var(--gridSpace) 32px;
            z-index: -2;

           body:not(.menu-opened) & {
            transform: translateX(100%);
            pointer-events: none;
           }
        }
    }
    &__right {
        display: flex;
        align-items:center;
        justify-content: flex-end;
        gap:4px;
    }
}

.logo {
    &__wrapper {
        color:inherit;
    }
}

.burgerMenu {
    --burgerwidth: 32px;
    --burgerheight: 2px;
    display: none;
	@include responsive("<xlarge") {
		display: block;
		padding: 0 6px;
        margin-right:-6px;

		&__bar {
			background: var(--brown2);
			display: block;
			pointer-events: none;
			height: var(--burgerheight);
			width: var(--burgerwidth);

			&--middle {
				width: 20px;
				margin: 10px 0 10px auto; 
				transition: all 350ms ease-out 350ms;
			}

			&--top, &--bottom {
				transition: all 350ms ease-out;
			}

			.menu-opened &--top {
				/*! purgecss ignore current */
				transform: translateY(10px) rotateZ(45deg);
				transition: all 350ms ease-out 350ms;
			}

			.menu-opened &--bottom {
				/*! purgecss ignore current */
				transform: translateY(-8px) rotateZ(-45deg);
				transition: all 350ms ease-out 350ms;
			}

			.menu-opened &--middle {
				/*! purgecss ignore current */
				width: 0;
				margin-block:7px;
				transition: all 350ms ease-out;
			}
		}

		
	}
}


.main-nav {  
   
    @include responsive('>xlarge') {
        text-align: center;
    }
    &__ul {
        list-style: none;
        margin:0 ;
        padding: 0;
        
        @include responsive('>xlarge') {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            .header--fixed & {
                justify-content: flex-start;
            }
        }
    }
    &__li {
        list-style: none;
        font-weight: 300;
        margin-bottom: 15px;
        line-height: 1.2;
        font-size:rem(22px);
        
        @include responsive('<xxlarge') {
            @include responsive('>xlarge') {
                font-size:rem(18px);
            }  
        }  
        @include responsive('>xlarge') {
            margin:0;
        } 
        a{
            display: inline-block;
            color:var(--brown2);
            padding: 8px 0px;
            
            @include responsive('>xlarge') {
                padding: 8px 16px;
            }
            @include on-event(){
                color: var(--green);
            }
        }
    }
}