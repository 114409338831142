// -----------------------------------------------------------------------------
// This file contains all styles related to discover.
// -----------------------------------------------------------------------------

.discover {
    position: relative;
    z-index: 2;   
    overflow: hidden;
    --actionsspace: 24px;

    @include responsive(">medium") {
        color: var(--white);
        --headerspace: 24px;
    }

    @include responsive("<medium") {
        --headerspace: 8px;

        .swiper-button-prev, .swiper-button-next {
            display: none;
        }
    }
   
    .swiper {
        @include responsive("<medium") {
            background: var(--beige);
            padding-bottom:var(--sectionspace);
          }
    }
    .swiper-pagination {
       
        @include responsive(">medium") {
            text-align:right;
            padding:0 16px;
            right: 0;
            width: auto;
            left: auto;
          }
    }

    &__item {
        overflow: hidden;
    }

    .swiper-nav {
        position: absolute;
        right: var(--gridSpace);
        width: 100px;
        height: var(--swiper-navigation-size);
    }



    &__content {
        --pspacing: 0.05em;       
        padding: 24px var(--gridSpace) ;
        z-index: 8;
        text-align: left;
        position: relative;

        @include responsive(">medium") {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding-block:40px;
        }

    }

    &__bg {
        overflow: hidden;
        position: relative;
        align-content:center;
        z-index: 1;

        @include responsive(">small") {           
            &:after {
                content: "";
                left: 0;
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 40%;
                z-index: 1;
                background: linear-gradient(0deg, var(--greendark2) 13%, rgba(0, 0, 0, 0.00) 100%);
               
            }
        }
        @include responsive(">large") {
            aspect-ratio: 1920 / 900;
        }

        @include responsive("<xlarge") {
            @include responsive(">small") {
                aspect-ratio: 1279/700;
            }
        }

        @include responsive("<small") {
            // aspect-ratio: 640/523;
            aspect-ratio: 640/370;
        }
      
    }
    &__logo {       
        width:var(--dlogo);
        background:var(--white);
        padding:8px 16px;
       align-content:center;      
       aspect-ratio: 2;
       
     
        @include responsive(">medium") {
            --dlogo:240px;
            margin-bottom:16px;
        }
        @include responsive(">large") {
            --dlogo:360px;
            margin-bottom:24px;
        }
        @include responsive("<medium") {
            --dlogo:160px;
            margin-bottom:16px;
        }
        img {
            width:100%;
        }
        &--visit{
            background:#DDE6EB;
        }
        &--holland {
            background:#00594c;
        }
    }
    &__img {       
       position:absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;

        img {
            transition: transform  calc(var(--duration) * 3) ease-out;
            position: absolute;
            left: 0;
            width: 100%;
            object-fit: cover;   

            @include responsive(">medium") {
                bottom: -30%;
                height: 160%;
            }

            @include responsive("<medium") {
                bottom: -24%;
                height: 148%;
            }
        }
    }


    &__header {
        --h2space: 0;
        max-width: 970px;
        margin-bottom: 16px;
        @include responsive(">large") {
            margin-bottom:24px;
        }
    }

    &__title {
       font-weight:bold;
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
        position: relative;
    }
}
